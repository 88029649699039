export const isPresent = value => {
  if(value === undefined || value === null || value === false) return false;
  switch(typeof value) {
    case 'string': return value.trim().length > 0;
    case 'object': return (Array.isArray(value) ? value : Object.keys(value)).length > 0;
    default: return true;
  }
}

export const isBlank = value => !isPresent(value);

export const isEmail = value => /^\S+@\S+\.\S+$/.test(value);
export const checkAttrsPresence = (form, attrs) => attrs.reduce((acc, key) => {
  if (!isPresent(form[key])) {
    acc[key] = true;
  }
  return acc;
}, {});
