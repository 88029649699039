import { isPresent, isEmail } from '../services/validators'
import AttachTermsModal from '../services/attach-terms-modal'

export default () => {
  let sessionForm = document.querySelector('[role=session-form]');
  let signInBtn = document.querySelector('[role=sign-in]');
  let acceptTerms = document.querySelector('[role=accept-terms]');

  if (acceptTerms) {
    acceptTerms.addEventListener('change', () => {
      if (acceptTerms.checked) {
        signInBtn.removeAttribute('disabled');
      } else {
        signInBtn.setAttribute('disabled', 'disabled');
      }
    });

    AttachTermsModal(acceptTerms.parentNode.querySelector('a'));
  }

  let isValidForm = () => {
    let namespace = sessionForm.querySelector('input[name=namespace]').value;
    let email = sessionForm.querySelector('input[name=email]').value;
    let password = sessionForm.querySelector('input[name=password]').value;
    return isPresent(namespace) &&
      isEmail(email) &&
      isPresent(password) &&
      (!acceptTerms || acceptTerms.checked);
  }

  let lockSubmit = true;
  sessionForm.addEventListener('submit', e => {
    if (lockSubmit) {
      e.preventDefault();
      return false;
    }
  });

  window.submitForm = () => {
    if (isValidForm()) {
      lockSubmit = false;
      sessionForm.submit();
    }
  }

  let getNamespace = () => sessionForm.querySelector('input[name="namespace"]').value.trim().toLowerCase();

  let onSignInClick = () => {
    if (window.grecaptcha && !gon.recaptchaWhitelist.includes(getNamespace())) {
      window.grecaptcha.execute();
    } else {
      window.submitForm();
    }
  }

  sessionForm.addEventListener('keypress', e => {
    if(e.key === 'Enter') {
      e.preventDefault();
      return false;
    }
  });

  signInBtn.addEventListener('click', onSignInClick);
};
